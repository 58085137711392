import store from "@/state/store";
import * as Utils from "@/utils";

export default [
  // Authentication
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the sign-up page
          next();
        }
      },
    },
  },
  {
    path: "/activate",
    name: "Activate",
    component: () => import("../views/account/activate.vue"),
    meta: {
      title: "Activate",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the activate page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the forgot-password page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the reset-password page
          next();
        }
      },
    },
  },
  {
    path: "/set-password",
    name: "Set password",
    component: () => import("../views/account/set-password.vue"),
    meta: {
      title: "Set Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the reset-password page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'promotion_graduation')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (!loggedUser) {
          next({ name: "login"});
        } else {
          if (loggedUser && loggedUser.role === 'Student') {
            // Redirect to the home page instead
            next({ name: "Admission Applications" });
          } else {
            // Continue to the dashboard page
            next();
          }
        }
      },
    },
    component: () => import("../views/dashboard/students-applications"),
  },
  // Academic Structure
  {
    path: "/academic-structure/faculties",
    name: "academic-structure-faculties",
    meta: {
      title: "Faculties",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'faculty')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/faculties"),
  },
  {
    path: "/academic-structure/modules",
    name: "academic-structure-modules",
    meta: {
      title: "Modules",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/modules"),
  },
  {
    path: "/academic-structure/qualifications",
    name: "academic-structure-qualifications",
    meta: {
      title: "Qualifications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'qualification')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/qualifications"),
  },
  {
    path: "/academic-structure/qualification-details",
    name: "academic-structure-qualification-details",
    meta: {
      title: "Qualification Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'qualification')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/qualification-details"),
  },
  {
    path: "/academic-structure/academic-years",
    name: "academic-structure-academic-years",
    meta: {
      title: "Academic Years",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'academic_year')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/academic-years"),
  },
  {
    path: "/academic-structure/student-types",
    name: "academic-structure-student-types",
    meta: {
      title: "Student Types",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'student_type')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-structure/student-types"),
  },
  // Hostel
  {
    path: "/hostel/hostels",
    name: "hostels",
    meta: {
      title: "Hostels",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'hostel')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/hostel/hostels"),
  },
  // Campus
  {
    path: "/campus/campuses",
    name: "campuses",
    meta: {
      title: "Campuses",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'campus')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/campus/campuses"),
  },
  {
    path: "/campus/set-campus-qualifications",
    name: "set-campus-qualifications",
    meta: {
      title: "Campus Qualifications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'campus')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/campus/set-campus-qualifications"),
  },
  {
    path: "/campus/qualification-details",
    name: "campus-qualification-details",
    meta: {
      title: "Qualification Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'campus')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/campus/qualification-details"),
  },
  // Fee
  {
    path: "/fee/fees",
    name: "fees",
    meta: {
      title: "Fees",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'fee')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/fee/fees"),
  },
  {
    path: "/fee/qualification-fees",
    name: "qualification-fees",
    meta: {
      title: "Qualifications Fees",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'fee')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/fee/qualification-fees"),
  },
  // Exam Setup
  {
    path: "/exam-setup/exam-setup",
    name: "exam-setup",
    meta: {
      title: "Exam Setup",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'exam')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/exam-setup/exam-setup"),
  },
  // High School Setup
  {
    path: "/high-school-setup/high-school-examinations",
    name: "high-school-examinations",
    meta: {
      title: "High School Examinations",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'high_school')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/high-school-setup/high-school-examinations"),
  },
  {
    path: "/high-school-setup/school-subjects",
    name: "high-school-subjects",
    meta: {
      title: "School Subjects",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'high_school')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/high-school-setup/school-subjects"),
  },
  {
    path: "/high-school-setup/evaluation-scales",
    name: "evaluation-scales",
    meta: {
      title: "Evaluation Scales",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'high_school')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/high-school-setup/evaluation-scales"),
  },
  // Processes
  {
    path: "/process/processes",
    name: "processes",
    meta: {
      title: "Processes",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'process')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/process/processes"),
  },
  // Student Profile - Student Portal
  {
    path: "/student/profile",
    name: "profile",
    meta: {
      title: "Profile",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/profile/simple"),
  },
  {
    path: "/student/profile-setting",
    name: "profile-setting",
    meta: {
      title: "Setting",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/profile/setting"),
  },
  // Admission Application - Student Portal
  {
    path: "/admission-application/admission-applications",
    name: "Admission Applications",
    meta: {
      title: "Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/admission-application/admission-applications"),
  },
  {
    path: "/admission-application/add-admission-application",
    name: "Add Admission Application",
    meta: {
      title: "Add Admission Application",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/admission-application/add-admission-application"),
  },
  {
    path: "/admission-application/edit-admission-application",
    name: "Edit Admission Application",
    meta: {
      title: "Edit Admission Application",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/admission-application/edit-admission-application"),
  },
  // Backoffice Application
  {
    path: "/backoffice-application",
    name: "Backoffice Application",
    meta: {
      title: "Backoffice Application",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application"),
  },
  {
    path: "/backoffice-application/add-student-profile",
    name: "Add Student Profile",
    meta: {
      title: "Add Student Profile",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application/add-student-profile"),
  },
  {
    path: "/backoffice-application/edit-student-profile",
    name: "Edit Student Profile",
    meta: {
      title: "Edit Student Profile",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application/edit-student-profile"),
  },
  {
    path: "/backoffice-application/backoffice-admission-applications",
    name: "Back Office Admission Applications",
    meta: {
      title: "Back Office Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application/backoffice-admission-applications"),
  },
  {
    path: "/backoffice-application/add-backoffice-admission-application",
    name: "Add Back Office Admission Applications",
    meta: {
      title: "Add Back Office Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application/add-backoffice-admission-application"),
  },
  {
    path: "/backoffice-application/edit-backoffice-admission-application",
    name: "Edit Back Office Admission Applications",
    meta: {
      title: "Edit Back Office Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_application')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-application/edit-backoffice-admission-application"),
  },
  // Admission Processing
  {
    path: "/admission-processing",
    name: "Admission Processing",
    meta: {
      title: "Admission Processing",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'admission_processing')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/admission-processing"),
  },
  {
    path: "/admission-processing/process-personal-details",
    name: "Process Personal Details",
    meta: {
      title: "Process Personal Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'admission_processing')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/admission-processing/process-personal-details"),
  },
  {
    path: "/admission-processing/process-admission-application",
    name: "Process Admission Application",
    meta: {
      title: "Process Admission Application",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'admission_processing')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/admission-processing/process-admission-application"),
  },
  {
    path: "/admission-processing/student-second-admission-applications",
    name: "Student Second Admission Applications",
    meta: {
      title: "Student Second Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'admission_processing')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/admission-processing/student-second-admission-applications"),
  },
  {
    path: "/admission-processing/student-admission-applications",
    name: "Student Admission Applications",
    meta: {
      title: "Student Admission Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'admission_processing')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/admission-processing/student-admission-applications"),
  },
  // Student Account
  {
    path: "/student-account",
    name: "Student Account",
    meta: {
      title: "Student Account",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'student_account')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/student-account"),
  },
  {
    path: "/student-account/details",
    name: "Student Account Details",
    meta: {
      title: "Student Account Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          // if (loggedUser.role === 'Student') {
          //   next({ name: "Admission Applications" });
          // } else if (loggedUser.role === 'User') {
          //   if (Utils.checkUserReadPermission(loggedUser, 'student_account')) {
          //     next();
          //   } else {
          //     next({ name: "default" });
          //   }
          // } else {
          //   next();
          // }
          next();
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/student-account/details"),
  },
  {
    path: "/student-account/invoice-details",
    name: "Student Invoice Details",
    meta: {
      title: "Student Invoice Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'student_account')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/student-account/invoice-details"),
  },
  // Registration - Student Portal
  {
    path: "/registration",
    name: "Registration",
    meta: {
      title: "Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/registration"),
  },
  {
    path: "/registration/register",
    name: "Student Registration",
    meta: {
      title: "Student Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        // && loggedUser.role === 'Student'
        if (loggedUser) {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/registration/register"),
  },
  {
    path: "/registration/view-register",
    name: "View Register",
    meta: {
      title: "Register",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/registration/view-register"),
  },
  // Backoffice Registration
  {
    path: "/backoffice-registration/proof-registration",
    name: "Proof of Registration",
    meta: {
      title: "Proof of Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'proof_registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/proof-registration"),
  },
  {
    path: "/backoffice-registration/registration-details",
    name: "Backoffice Registration Details",
    meta: {
      title: "Backoffice Registration Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/registration-details"),
  },
  {
    path: "/backoffice-registration/enable-registration",
    name: "Enable Registration",
    meta: {
      title: "Enable Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'enable_registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/enable-registration"),
  },
  {
    path: "/backoffice-registration/admission-application-enables",
    name: "Admission Application Enables",
    meta: {
      title: "Admission Application Enables",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/admission-application-enables"),
  },
  {
    path: "/backoffice-registration/backoffice-registration",
    name: "Backoffice Registration",
    meta: {
      title: "Backoffice Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'backoffice_registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/backoffice-registration"),
  },
  {
    path: "/backoffice-registration/student-registrations",
    name: "Backoffice Student Registrations",
    meta: {
      title: "Backoffice Student Registrations",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/student-registrations"),
  },
  {
    path: "/backoffice-registration/validate-proof-payment",
    name: "Validate Proof of Payments",
    meta: {
      title: "Validate Proof of Payments",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'validate_proof_payment')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/validate-proof-payment"),
  },
  {
    path: "/backoffice-registration/cancel-registration",
    name: "Cancel Registration",
    meta: {
      title: "Cancel Registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'cancel_registration')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/backoffice-registration/cancel-registration"),
  },

  // Continues Assessments(CA) and Examination
  {
    path: "/ca-examination/ca",
    name: "Continuous Assessments",
    meta: {
      title: "Continues Assessments",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_ca') ||
                Utils.checkUserReadPermission(loggedUser, 'module_final_ca') ||
                Utils.checkUserReadPermission(loggedUser, 'module_class_group') ||
                Utils.checkUserReadPermission(loggedUser, 'module_ca_setup')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/ca-examination/ca"),
  },
  {
    path: "/ca-examination/student-ca",
    name: "Student Continuous Assessments",
    meta: {
      title: "Student Continues Assessments",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_ca') ||
                Utils.checkUserReadPermission(loggedUser, 'module_final_ca') ||
                Utils.checkUserReadPermission(loggedUser, 'module_class_group') ||
                Utils.checkUserReadPermission(loggedUser, 'module_ca_setup')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/ca-examination/student-ca"),
  },
  {
    path: "/ca-examination/exam",
    name: "Examination",
    meta: {
      title: "Examination",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'final_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'total_module_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'release_examination_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'module_papers_setup')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/ca-examination/exam"),
  },
  {
    path: "/ca-examination/student-exam",
    name: "Student Examination",
    meta: {
      title: "Student Examination",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'final_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'total_module_exam_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'release_examination_marks') ||
                Utils.checkUserReadPermission(loggedUser, 'module_papers_setup')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/ca-examination/student-exam"),
  },
  // Continues Assessments(CA) and Examination - Student Portal
  {
    path: "/student-ca-examination/ca",
    name: "Continuous Assessments (Student Portal)",
    meta: {
      title: "Continues Assessments",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-ca-examination/ca"),
  },
  {
    path: "/student-ca-examination/ca-details",
    name: "Continuous Assessment Details (Student Portal)",
    meta: {
      title: "Continues Assessment Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-ca-examination/ca-details"),
  },
  {
    path: "/student-ca-examination/exam",
    name: "Examination (Student Portal)",
    meta: {
      title: "Examination",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-ca-examination/exam"),
  },
  // Module Management - Student Portal
  {
    path: "/student-module-management/cancellation",
    name: "Request for module cancellation",
    meta: {
      title: "Request for module cancellation",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-module-management/cancellation"),
  },
  {
    path: "/student-module-management/cancellation-details",
    name: "Student - Module Cancellation",
    meta: {
      title: "Student - Module Cancellation",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-module-management/cancellation-details"),
  },
  {
    path: "/student-module-management/exemption",
    name: "Request for module exemption",
    meta: {
      title: "Request for module exemption",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-module-management/exemption"),
  },
  {
    path: "/student-module-management/exemption-details",
    name: "Student - Module Exemption",
    meta: {
      title: "Student - Module Exemption",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-module-management/exemption-details"),
  },
  // Module Management
  {
    path: "/module-management/cancellation-students",
    name: "Students requested module cancellation",
    meta: {
      title: "Students requested module cancellation",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/cancellation-students"),
  },
  {
    path: "/module-management/cancellation-student-registrations",
    name: "Student Registrations requested module cancellation",
    meta: {
      title: "Student Registrations requested module cancellation",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/cancellation-student-registrations"),
  },
  {
    path: "/module-management/cancellation-details",
    name: "Student Module Cancellation Details",
    meta: {
      title: "Student Module Cancellation Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/cancellation-details"),
  },
  {
    path: "/module-management/exemption-students",
    name: "Students requested module exemption",
    meta: {
      title: "Students requested module exemption",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/exemption-students"),
  },
  {
    path: "/module-management/exemption-student-registrations",
    name: "Student Registrations requested module exemption",
    meta: {
      title: "Student Registrations requested module exemption",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/exemption-student-registrations"),
  },
  {
    path: "/module-management/exemption-details",
    name: "Student Module Exemption Details",
    meta: {
      title: "Student Module Exemption Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/exemption-details"),
  },
  {
    path: "/module-management/manual-cancellation-student-registrations",
    name: "Student Registrations manual module cancellation",
    meta: {
      title: "Student Registrations manual module cancellation",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/manual-cancellation-student-registrations"),
  },
  {
    path: "/module-management/manual-cancellation-details",
    name: "Student Module Manual Cancellation Details",
    meta: {
      title: "Student Module Manual Cancellation Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/manual-cancellation-details"),
  },
  {
    path: "/module-management/module-registration-students",
    name: "Students module registration",
    meta: {
      title: "Students module registration",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/module-registration-students"),
  },
  {
    path: "/module-management/manual-registration-details",
    name: "Student module manual registration details",
    meta: {
      title: "Student module manual registration details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'module_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/module-management/manual-registration-details"),
  },
  // Change of Campus - Student Portal
  {
    path: "/student-campus-change",
    name: "Change of Campus",
    meta: {
      title: "Change of Campus",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next();
        } else {
          // Continue to the dashboard page
          next({ name: "default" });
        }
      },
    },
    component: () => import("../views/student-campus-change"),
  },
  {
    path: "/campus-change",
    name: "Approve Change of Campus",
    meta: {
      title: "Approve Change of Campus",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser && loggedUser.role === 'Student') {
          // Redirect to the home page instead
          next({ name: "Admission Applications" });
        } else {
          // Continue to the dashboard page
          next();
        }
      },
    },
    component: () => import("../views/campus-change"),
  },
  // User Management
  {
    path: "/user-management",
    name: "Users",
    meta: {
      title: "Users",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'user_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/user-management"),
  },
  {
    path: "/user-management/permissions",
    name: "User Permissions",
    meta: {
      title: "User Permissions",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'user_management')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/user-management/permissions"),
  },
  // Promotions & Graduations
  {
    path: "/promotion-graduation",
    name: "Promotions & Graduations",
    meta: {
      title: "Promotions & Graduations",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'promotion_graduation')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/promotion-graduation"),
  },
  {
    path: "/promotion-graduation/promotion-graduation-details",
    name: "Promotions & Graduations Details",
    meta: {
      title: "Promotions & Graduations Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'promotion_graduation')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/promotion-graduation/promotion-graduation-details"),
  },
  // Academic Record
  {
    path: "/academic-record",
    name: "Students - Academic Record",
    meta: {
      title: "Students - Academic Record",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'academic_record')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-record"),
  },
  {
    path: "/academic-record/student-qualifications",
    name: "Student Qualifications - Academic Record",
    meta: {
      title: "Student Qualifications - Academic Record",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'academic_record')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-record/student-qualifications"),
  },
  {
    path: "/academic-record/details",
    name: "Student Academic Record Details",
    meta: {
      title: "Student Academic Record Details",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'academic_record')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/academic-record/details"),
  },
  // Dashboard
  {
    path: "/dashboard/students-applications",
    name: "Reports - Students Applications",
    meta: {
      title: "Reports - Students Applications",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'students_applications')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/dashboard/students-applications"),
  },
  {
    path: "/dashboard/students-registrations",
    name: "Reports - Students Registrations",
    meta: {
      title: "Reports - Students Registrations",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'students_registrations')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/dashboard/students-registrations"),
  },
  {
    path: "/dashboard/students-promotion",
    name: "Reports - Students Promotion",
    meta: {
      title: "Reports - Students Promotion",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'students_promotion')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/dashboard/students-promotion"),
  },
  {
    path: "/dashboard/academic-structure",
    name: "Reports - Academic Structure",
    meta: {
      title: "Reports - Academic Structure",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        let loggedUser = JSON.parse(localStorage.getItem('authUser'));
        if (loggedUser) {
          if (loggedUser.role === 'Student') {
            next({ name: "Admission Applications" });
          } else if (loggedUser.role === 'User') {
            if (Utils.checkUserReadPermission(loggedUser, 'academic_structure')) {
              next();
            } else {
              next({ name: "default" });
            }
          } else {
            next();
          }
        } else {
          next({ name: "login" });
        }
      },
    },
    component: () => import("../views/dashboard/academic-structure"),
  },

  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: {
      title: "Google Maps", authRequired: true,
    },
    component: () => import("../views/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { title: "Leaflet Maps", authRequired: true },
    component: () => import("../views/maps/leaflet/index"),
  },
];
